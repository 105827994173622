/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

  https://polaris.a2z.com/develop/integration/react/
************************************************************************/
@use '@cloudscape-design/design-tokens/index.scss' as awsui;

.custom-header {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.outputpanel {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.mark_label {
  background-color: yellow;
}
.url_width{
  word-wrap: break-word;
}
.extend_it{
  width: max-content;
}
.input_width{
  width:80px;
}
.large_input_width{
  width:240px;
}
.enabled_pricing{
  disabled:false;
}
.disabled_pricing{
  disabled:true;
}
.image_class{
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
}
.wip_class{
  position: absolute;
  top: 30%;
  left: 40%;
}
.wip_class1{
  position: relative;
  top: 15%;
}
.inspect_img{
  vertical-align: middle;
  text-align: center;
}
.bottompanel{
  --space-xxxl-bo9z7f:20px;
}
.toppanel{
  // position: fixed;
  --space-xxxl-bo9z7f:25px;
  // top: 0px;
  // padding: 0px;
}
.text_color_red{
  color: red;
}
.highlight {
  background-color: yellowgreen;
}

.chat_input_text{
  width: 500px;
}

.chat-msg pre{
  width: "95%";
  text-align: left;
  padding-top: 1px;
  word-wrap: break-word;
  overflow-x: auto;
  white-space: pre-wrap;
}